import { LOCAL_STORAGE_KEY } from 'config/constants'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import styled from 'styled-components'
import { isAddress } from 'utils'
import GetStarted from './components/GetStarted'
import Hero from './components/Hero'
import Instruction from './components/Instruction'
import Referral from './components/Referral'
import Social from './components/Social'
import StartMine from './components/StartMine'
import Welcome from './components/Welcome'

const Wrapper = styled.div`
  margin-top: 97px;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: 88px;
  }
`

const Mining = () => {
  const { query } = useRouter()

  // handle set referral code to local storage
  useEffect(() => {
    if (query?.startapp && typeof query?.startapp === 'string' && isAddress(query?.startapp)) {
      localStorage.setItem(LOCAL_STORAGE_KEY.REFERRAL_CODE, query?.startapp)
    }
  }, [query?.startapp])

  return (
    <Wrapper>
      <Hero />
      <Welcome />
      <StartMine />
      <Referral />
      <GetStarted />
      <Instruction />
      <Social />
    </Wrapper>
  )
}

export default Mining
