import { useTranslation } from 'contexts/Localization'
import React from 'react'
import styled from 'styled-components'

const TopWrapper = styled.div`
  padding: 48px 0 0;
  background-color: #001db2;
`

const BottomWrapper = styled.div`
  padding: 0 0 48px;
  background-color: #ffff0b;
`

const Content = styled.div`
  background-color: #000;

  b {
    vertical-align: top;
    display: inline-block;
  }

  &:has(.top) {
    border-radius: 12px 12px 0 0;
    padding: 24px 24px 0;
  }

  .top {
    max-width: 500px;
    margin: 0 auto;

    .title {
      font-family: Clash Display;
      font-size: 24px;
      font-weight: 600;
      line-height: 29.52px;
      letter-spacing: 0.01em;
      text-align: center;
      color: #fff;
      border-bottom: 1px solid #ffffff1f;
      padding-bottom: 1rem;
    }

    .note {
      margin: 1rem 0 0;
      padding: 12px 24px;
      background: #ecff121f;
      color: #ffff0b;
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
    }
  }

  &:has(.bottom) {
    border-radius: 0 0 12px 12px;
    padding: 24px 0;
  }

  .bottom {
    .cards {
      padding: 32px 24px 0;
      display: flex;
      gap: 3rem;
      flex-direction: column;

      ${({ theme }) => theme.mediaQueries.lg} {
        flex-direction: row;
        gap: 2rem;
        padding: 32px 44px 0;
      }

      .card {
        flex: 1;
        background-color: #fff;
        color: #000;
        border-radius: 8px;
        position: relative;
        padding: 16px;
        font-family: Clash Display;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;

        ${({ theme }) => theme.mediaQueries.lg} {
          padding: 24px;
          font-size: 20px;
        }

        .step {
          position: absolute;
          padding: 6px 24px;
          background-color: #ffff0b;
          top: -26px;
          font-family: Clash Display;
          font-size: 24px;
          font-weight: 700;
          line-height: 28px;
          text-align: left;
          border-radius: 6px 6px 0 0;
        }

        .blue {
          color: #001db2;
        }
      }
    }
  }
`

const Instruction = () => {
  const { t } = useTranslation()

  return (
    <>
      <TopWrapper>
        <div className="container">
          <Content>
            <div className="top">
              <div className="title">{t('Instructions')}</div>
              <div className="note">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M22.0599 10.7401L20.7099 9.16008C20.4599 8.86008 20.2499 8.30008 20.2499 7.90008V6.20008C20.2499 5.14008 19.3799 4.27008 18.3199 4.27008H16.6199C16.2199 4.27008 15.6499 4.06008 15.3499 3.81008L13.7699 2.46008C13.0799 1.87008 11.9499 1.87008 11.2599 2.46008L9.65988 3.81008C9.35988 4.06008 8.79988 4.27008 8.39988 4.27008H6.66988C5.60988 4.27008 4.73988 5.14008 4.73988 6.20008V7.90008C4.73988 8.29008 4.53988 8.85008 4.28988 9.15008L2.93988 10.7401C2.35988 11.4401 2.35988 12.5601 2.93988 13.2401L4.28988 14.8301C4.53988 15.1201 4.73988 15.6901 4.73988 16.0801V17.7901C4.73988 18.8501 5.60988 19.7201 6.66988 19.7201H8.40988C8.79988 19.7201 9.36988 19.9301 9.66988 20.1801L11.2499 21.5301C11.9399 22.1201 13.0699 22.1201 13.7599 21.5301L15.3399 20.1801C15.6399 19.9301 16.1999 19.7201 16.5999 19.7201H18.2999C19.3599 19.7201 20.2299 18.8501 20.2299 17.7901V16.0901C20.2299 15.6901 20.4399 15.1301 20.6899 14.8301L22.0399 13.2501C22.6499 12.5701 22.6499 11.4401 22.0599 10.7401ZM11.7499 8.13008C11.7499 7.72008 12.0899 7.38008 12.4999 7.38008C12.9099 7.38008 13.2499 7.72008 13.2499 8.13008V12.9601C13.2499 13.3701 12.9099 13.7101 12.4999 13.7101C12.0899 13.7101 11.7499 13.3701 11.7499 12.9601V8.13008ZM12.4999 16.8701C11.9499 16.8701 11.4999 16.4201 11.4999 15.8701C11.4999 15.3201 11.9399 14.8701 12.4999 14.8701C13.0499 14.8701 13.4999 15.3201 13.4999 15.8701C13.4999 16.4201 13.0599 16.8701 12.4999 16.8701Z"
                    fill="#ECFF12"
                  />
                </svg>
                <span>{t('The deposited BNB cannot be withdrawn.')}</span>
              </div>
            </div>
          </Content>
        </div>
      </TopWrapper>
      <BottomWrapper>
        <div className="container">
          <Content>
            <div className="bottom">
              <div className="cards">
                <div className="card">
                  <div className="step">1</div>
                  <div>{t('Send BNB To Metamask/Trustwallet wallet on Binance smart Chain Network (BSC).')}</div>
                </div>
                <div className="card">
                  <div className="step">2</div>
                  <div>
                    {t('In Metamask/Trustwallet Browser Connect to')} <b>PANCAKESWAP</b> {t('and swap to BNB')}
                  </div>
                </div>
                <div className="card">
                  <div className="step">3</div>
                  <div>
                    <div>{t('At Bunny Mining site:')}</div>
                    <div>
                      <b className="blue">A.</b> {t('Enter the BNB amount to Hire Miners.')}
                    </div>
                    <div>
                      <b className="blue">B.</b> {t('Click Hire miners.')}
                    </div>
                    <div>
                      <b className="blue">C.</b> {t('Compound Daily to hire more miners to increase your profit.')}
                    </div>
                    <div>
                      <b className="blue">D.</b> {t('Withdraw Profit using the')} <b>{t('Collect Rewards')}</b>{' '}
                      {t('button')}.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </div>
      </BottomWrapper>
    </>
  )
}

export default Instruction
