import React from 'react'
import styled from 'styled-components'

const HeroWrapper = styled.div`
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .discover {
    position: absolute;
    bottom: 5%;
    left: 15%;
    color: #fff;
    font-family: 'Clash Display';
    font-size: 20px;
    font-weight: 400;
    line-height: 19.68px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    display: none;

    ${({ theme }) => theme.mediaQueries.md} {
      display: flex;
    }
  }
`

const Hero = () => {
  return (
    <HeroWrapper>
      <img src="/images/mining/hero-v2.gif" alt="hero" />
      <div className="discover">
        <svg width="31" height="76" viewBox="0 0 31 76" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_b_9735_3682)">
            <rect x="0.5" width="30" height="49" rx="15" fill="url(#paint0_linear_9735_3682)" fillOpacity="0.16" />
            <rect x="1.5" y="1" width="28" height="47" rx="14" stroke="white" strokeWidth="2" />
          </g>
          <path d="M15.5 11L15.5 22" stroke="white" strokeWidth="3" strokeLinecap="round" />
          <path
            d="M15.2929 75.7071C15.6834 76.0976 16.3166 76.0976 16.7071 75.7071L23.0711 69.3431C23.4616 68.9526 23.4616 68.3195 23.0711 67.9289C22.6805 67.5384 22.0474 67.5384 21.6569 67.9289L16 73.5858L10.3431 67.9289C9.95262 67.5384 9.31946 67.5384 8.92893 67.9289C8.53841 68.3195 8.53841 68.9526 8.92893 69.3431L15.2929 75.7071ZM17 54C17 53.4477 16.5523 53 16 53C15.4477 53 15 53.4477 15 54L17 54ZM17 75L17 54L15 54L15 75L17 75Z"
            fill="white"
          />
          <defs>
            <filter
              id="filter0_b_9735_3682"
              x="-11.5"
              y="-12"
              width="54"
              height="73"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="6" />
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9735_3682" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9735_3682" result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear_9735_3682"
              x1="-8.02273"
              y1="-41.6038"
              x2="53.212"
              y2="-22.0215"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D7EDED" />
              <stop offset="1" stopColor="#CCEBEB" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <span>Discover</span>
      </div>
    </HeroWrapper>
  )
}

export default Hero
