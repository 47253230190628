/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 48px 0;
  background: radial-gradient(47.31% 47.31% at 50% 46.67%, #ffff8f 0%, #ffff32 84.94%);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #ffff8f;
    background-image: url('/images/mining/welcome-bg.jpg');
    background-size: cover;
    background-blend-mode: exclusion;
    background-position: center;
    opacity: 0.1;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .welcome-block {
      padding: 12px 24px;
      background: #fff;
      border-radius: 100px;
      font-family: Clash Display;
      font-size: 20px;
      font-weight: 500;
      line-height: 31.2px;
      text-align: center;
      color: #000;

      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 24px;
      }
    }

    .welcome-title {
      font-family: Death Star;
      font-size: 24px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: 0.01em;
      text-align: center;
      color: #000;
      max-width: 900px;

      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 32px;
      }
    }

    .welcome-subtitle {
      font-family: Clash Display;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.01em;
      text-align: center;
      color: #000;

      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 20px;
      }

      b {
        vertical-align: top;
      }
    }

    .mining-buttons {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      a {
        padding: 12px 24px;
        border-radius: 100px;
        border: 1px solid #000;
        font-family: Clash Display;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0.01em;
        text-align: center;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        transition: all 0.2s ease;

        ${({ theme }) => theme.mediaQueries.md} {
          font-size: 16px;
        }

        &:first-child {
          background: #fff;

          &:hover {
            span {
              color: #fff;
            }
          }

          span {
            color: #000;
          }
        }

        &:last-child {
          background: #000;

          span {
            color: #ffff0b;
          }
        }

        &:hover {
          text-decoration: none;
          background-color: #015cf7;
          color: initial;
        }
      }
    }
  }
`

const Welcome = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <div className="container">
        <div className="content">
          <svg width="67" height="52" viewBox="0 0 67 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.33815 34.0688H4.52734V9.86193H9.05437V5.37918H12.676V1.79297H54.3246V5.37918H57.9463V9.86193H62.4733V34.9654H58.8517V38.5516H54.3246V42.1378H38.0273V46.6206H33.5003V50.2068H32.5949V46.6206H28.9733V42.1378H12.676V38.5516H8.14897V34.0688H6.33815Z"
              fill="#015CF7"
            />
            <path
              d="M59.7569 8.06897V3.58621H56.1352V0H10.865V3.58621H7.24334V8.06897H2.71631V35.8621H6.33793V40.3448H10.865V43.931H14.4866V45.7241H27.1623V48.4138H30.7839V52H35.3109V48.4138H39.8379V45.7241H52.5136V43.931H56.1352V40.3448H60.6623V36.7586H64.2839V8.06897H59.7569ZM60.6623 33.1724H57.0406V36.7586H39.8379V40.3448H36.2163V44.8276H30.7839V40.3448H27.1623V36.7586H9.95955V32.2759H6.33793V11.6552H10.865V7.17241H14.4866V3.58621H52.5136V7.17241H56.1352V11.6552H60.6623V33.1724Z"
              fill="black"
            />
            <path
              d="M18.1079 18.8271H23.5403V24.2065H18.1079V18.8271ZM30.7836 18.8271H36.216V24.2065H30.7836V18.8271ZM43.4593 18.8271H48.8917V24.2065H43.4593V18.8271Z"
              fill="white"
            />
          </svg>
          <div className="welcome-block">👋 {t('Welcome to Bunny Mining')}</div>
          <div className="welcome-title">
            {t('Bunny provides the most optimal mining solution for everyone in the blockchain world!')}
          </div>
          <div className="welcome-subtitle">
            <span>
              ⚡ {t('Earn')} <b>8%</b> {t('rewards every day and')} <b>2,920%</b> ROI
            </span>
          </div>
          <div className="mining-buttons">
            <a
              href="https://www.dexview.com/bsc/0x2AFc0a4850d22fd61d8c52E355769D941bf43f86"
              target="_blank"
              rel="noreferrer"
            >
              <span>{t('Buy')} $BUNNY</span>
            </a>
            <a href="#">
              <span>{t('Start Miner')}</span>⛏
            </a>
          </div>
          <div>
            <img src="/images/mining/welcome-block.png" alt="mining" />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Welcome
