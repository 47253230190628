import { ChainId } from '@pancakeswap/sdk'
import { SMC_BUNNY_MINING_ADDRESS } from 'config/constants/endpoints'
import React from 'react'
import styled from 'styled-components'
import { getBscScanLink } from 'utils'

const Wrapper = styled.div`
  padding: 48px 0;
  background-color: #ffff0b;

  .networks {
    background-color: #fff;
    border-radius: 24px;
    padding: 24px;
    border: 1px solid #0000001f;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1080px;
    margin: 0 auto;

    ${({ theme }) => theme.mediaQueries.md} {
      gap: 2rem;
      padding: 44px;
    }
  }
`

const Network = styled.a<{ background?: string; color?: string }>`
  width: 100%;
  border-radius: 100px;
  padding: 12px 12px 12px 24px;
  background-color: ${({ background }) => background || '#000'};
  color: ${({ color }) => color || '#fff'};
  font-family: Clash Display;
  font-size: 20px;
  font-weight: 600;
  line-height: 39.36px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  &:hover {
    color: ${({ color }) => color || '#fff'};
    text-decoration: none;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 300px;
    font-size: 32px;
  }
`

const ExternalIcon = styled.div`
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 100px;
`

const Social = () => {
  return (
    <Wrapper>
      <div className="container">
        <div className="networks">
          <Network background="#000" color="#FFF" href="https://twitter.com/BunnyChiliz" target="_blank">
            <span>X ( Twiter )</span>
            <ExternalIcon>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.97375 2.6924L0.666504 11.9996L2.33417 13.6673L11.6415 4.3599V11.8902H13.9999V0.333984H2.44376V2.6924H9.97375Z"
                  fill="black"
                />
              </svg>
            </ExternalIcon>
          </Network>
          <Network background="#92deff" color="#000" href="https://t.me/Bunny_Chiliz" target="_blank">
            <span>Telegram</span>
            <ExternalIcon>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.97375 2.6924L0.666504 11.9996L2.33417 13.6673L11.6415 4.3599V11.8902H13.9999V0.333984H2.44376V2.6924H9.97375Z"
                  fill="black"
                />
              </svg>
            </ExternalIcon>
          </Network>
          <Network
            background="#fecdff"
            color="#000"
            href="https://www.dexview.com/bsc/0x2AFc0a4850d22fd61d8c52E355769D941bf43f86"
            target="_blank"
          >
            <span>Chart</span>
            <ExternalIcon>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.97375 2.6924L0.666504 11.9996L2.33417 13.6673L11.6415 4.3599V11.8902H13.9999V0.333984H2.44376V2.6924H9.97375Z"
                  fill="black"
                />
              </svg>
            </ExternalIcon>
          </Network>
          <Network
            background="#ccff9c"
            color="#000"
            href={getBscScanLink(SMC_BUNNY_MINING_ADDRESS, 'address', ChainId.MAINNET)}
            target="_blank"
          >
            <span>Contract</span>
            <ExternalIcon>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.97375 2.6924L0.666504 11.9996L2.33417 13.6673L11.6415 4.3599V11.8902H13.9999V0.333984H2.44376V2.6924H9.97375Z"
                  fill="black"
                />
              </svg>
            </ExternalIcon>
          </Network>
          <Network background="#fdff8d" color="#000" href="https://bunnychz.gitbook.io/whitepaper" target="_blank">
            <span>Whitepaper</span>
            <ExternalIcon>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.97375 2.6924L0.666504 11.9996L2.33417 13.6673L11.6415 4.3599V11.8902H13.9999V0.333984H2.44376V2.6924H9.97375Z"
                  fill="black"
                />
              </svg>
            </ExternalIcon>
          </Network>
        </div>
      </div>
    </Wrapper>
  )
}

export default Social
